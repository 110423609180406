export const capitalizeFirstLetter = (str: string | undefined) => {
  if (!str || str.length < 1) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
};

export const turnSnakeCaseIntoSpacedText = (
  string?: string | null,
  subString?: boolean,
  ignoreFirstWord?: boolean,
  length: number = 3
) => {
  if (!string) {
    return "";
  }
  const wordArr = string?.split("_");

  if (!subString || wordArr.length === 0) {
    if (ignoreFirstWord && wordArr.length > 0) {
      wordArr.shift();
    }
    return capitalizeFirstLetter(wordArr.join(" "));
  }

  return capitalizeFirstLetter(
    wordArr.length >= 3
      ? wordArr.slice(1, length).join(" ")
      : wordArr[wordArr.length - 1]
  );
};

export const turnCamelCaseIntoSpacedText = (text: string) => {
  if (text.length === 0) {
    return "";
  }

  let result = text[0].toUpperCase();

  for (let i = 1; i < text.length; i++) {
    if (text[i] === text[i].toUpperCase()) {
      result += " ";
    }

    result += text[i];
  }

  return capitalizeFirstLetter(result.toLowerCase());
};

export const turnStringIntoLowerSnakeCase = (str: string): string => {
  return str.replace(/ |-|\//g, "_").toLowerCase();
};

export const turnSnakeCaseIntoJoinedUpperCase = (str: string) => {
  return str
    .split("_")
    .map((s) => capitalizeFirstLetter(s))
    .join("");
};

export const turnSnakeCaseIntoSpacedUpperCase = (str: string) => {
  return str
    .split("_")
    .map((s) => capitalizeFirstLetter(s))
    .join(" ");
};

export const convertRecordDataToString = (
  data: Record<string, object> | undefined
): string => {
  if (!data) {
    return "";
  }

  let result = "";
  Object.keys(data).map((r) => {
    if (result) {
      result += ", ";
    }

    const values = [];
    for (const [key, value] of Object.entries(data[r])) {
      values.push(`${key} = ${value}`);
    }

    if (values.length > 0) {
      result += `${r}: `;
      result += values.join(", ");
    }
  });
  return result;
};

export const displayStatus = (value: string | string[] | undefined): string => {
  if (!value) {
    return "-";
  }

  if (typeof value === "string") {
    return value;
  }

  return value.join(", ");
};

export const truncateString = (value: string, limit: number) =>
  value.length > limit ? value.substring(0, limit) + " ..." : value;

export const sortStringValue = (a: string, b: string) => {
  const sizes = ["not", "none", "small", "low", "medium", "large", "high"];
  if (
    !a ||
    !b ||
    typeof a === "boolean" ||
    typeof b === "boolean" ||
    typeof a === "number" ||
    typeof b === "number"
  ) {
    return 0;
  }
  const aIndex = sizes.findIndex((size) => a.toLowerCase().indexOf(size) > -1);
  const bIndex = sizes.findIndex((size) => b.toLowerCase().indexOf(size) > -1);
  if (aIndex === -1 || bIndex === -1) {
    return a.localeCompare(b);
  }
  return aIndex > bIndex ? 1 : -1;
};

export const getStringValue = (value: any) => {
  if (typeof value === "string") return value;
  try {
    return JSON.stringify(value);
  } catch (error) {
    console.error("Error getting string value", error);
    return "";
  }
};

export const isJson = (value: any) => {
  const str = getStringValue(value);

  try {
    if (JSON.parse(str)) return true;
  } catch (error) {
    console.error("Error parsing string", error);
  }
  return false;
};
