import { UserRoleEnum } from "@/apis/services/OrganizationService";

export const isAdmin = (roles: string[] | undefined) => {
  if (!roles || roles.length === 0) return false;
  return roles.includes(UserRoleEnum.Admin);
};

export const isOrganizationAdmin = (roles: string[] | undefined) => {
  if (!roles || roles.length === 0) return false;
  if (isAdmin(roles)) return true;
  return roles.includes(UserRoleEnum.OrganizationAdmin);
};

export const isAnalyst = (roles: string[] | undefined) => {
  if (!roles || roles.length === 0) return false;
  if (isOrganizationAdmin(roles)) return true;
  return roles.includes(UserRoleEnum.Analyst);
};

export const isClient = (roles: string[] | undefined) => {
  if (!roles || roles.length === 0) return false;
  if (isAnalyst(roles)) return true;
  return roles.includes(UserRoleEnum.Client);
};

export const isGuest = (roles: string[] | undefined) => {
  if (!roles || roles.length === 0) return false;
  if (isClient(roles)) return true;
  return roles.includes(UserRoleEnum.Guest);
};
