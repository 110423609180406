export const getErrorDetails = (error?: any): string[] => {
  const noDetails = "No details.";
  // The problem with the 'error' is that it can contain different types of data.
  // Our alert can show a list of strings as details but we need to extract
  // that list from our error object. Sometimes this data comes as a single string,
  // sometimes it comes as an array, sometimes a double array or sometimes not at all.
  if (!error) return [noDetails];
  if (!error.error && !error.stack) {
    if (error?.message) return [error.message];
    if (typeof error === "string") return [error];
    return [noDetails];
  }

  try {
    const detail =
      error.error?.detail ??
      error.error?.message ??
      error.stack?.detail ??
      noDetails;
    if (typeof detail === "string") return [detail];

    if (Array.isArray(detail) && detail.length > 0) {
      const flattened = detail.flatMap((s) => s);

      return flattened
        .map((o: any) => {
          if (typeof o === "string") return o;
          if (typeof o === "object") {
            if (o.hasOwnProperty("Code") || o.hasOwnProperty("Message")) {
              const code: string = (o["Code"] ?? "").toString();
              const message: string = (o["Message"] ?? "").toString();
              return code ? `${code}: ${message}` : `${message}`;
            }
            if (o.hasOwnProperty("loc") || o.hasOwnProperty("msg")) {
              let code = "";
              const loc = o["loc"];
              if (Array.isArray(loc) && loc.length > 0) {
                code = loc[loc.length - 1];
              }
              const message: string = (o["msg"] ?? "").toString();
              return code ? `${code}: ${message}` : `${message}`;
            }
            return "";
          }
          return "";
        })
        .filter((s) => !!s);
    }
  } catch (error: any) {
    console.error("Converting the error details failed: ", error);
  }

  return [noDetails];
};
