import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Image from "next/image";
import { usePathname } from "next/navigation";

export const IRISLogo = () => {
  const pathName = usePathname();
  const isPreview = pathName?.includes("preview");

  return (
    <>
      <Stack pl={0.5} alignItems="flex-end">
        <Typography
          component="span"
          sx={{
            fontWeight: "bold",
            fontFamily: "Times New Roman",
            lineHeight: 0.8,
            fontSize: isPreview ? "3rem" : "1.1rem",
            letterSpacing: "0.15em",
            color: grey[900],
          }}
        >
          IRIS
        </Typography>
        <Typography
          component="span"
          sx={{
            fontWeight: "bold",
            fontStyle: "italic",
            fontFamily: "Times New Roman",
            lineHeight: 0.8,
            fontSize: isPreview ? "1.75rem" : ".75rem",
            paddingRight: isPreview ? ".50rem" : ".25rem",
            color: grey[500],
          }}
        >
          by
        </Typography>
      </Stack>
      <Typography
        component="span"
        sx={{
          fontWeight: "bold",
          fontFamily: "Times New Roman",
          lineHeight: isPreview ? 0.7 : 1,
          fontSize: isPreview ? "6.3rem" : "2.2rem",
          color: grey[200],
        }}
      >
        /
      </Typography>
      <Image
        priority
        src="/Arup_Red.svg"
        height={isPreview ? 55 : 33}
        width={isPreview ? 210 : 88}
        alt="IRIS by ARUP"
      />
    </>
  );
};
