import React, { useEffect } from "react";

import type { AlertColor, SnackbarOrigin } from "@mui/material";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const DEFAULT_LOCATION: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

export const EMPTY_ALERT: AlertParameters = {
  content: {},
  properties: {},
};

export interface AlertContent {
  showAlert?: boolean;
  body?: string;
  details?: string[];
}

export interface AlertProperties {
  location?: SnackbarOrigin;
  severity?: AlertColor;
  duration?: number;
}

export interface AlertParameters {
  content: AlertContent;
  properties?: AlertProperties;
  sx?: Object;
}

export const Alert = (props: AlertParameters) => {
  const { content, properties } = props;
  const [alertOpen, setAlertOpen] = React.useState(false);

  useEffect(() => {
    if (content?.showAlert) {
      setAlertOpen(true);
    }
  }, [content]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Snackbar
      open={alertOpen}
      autoHideDuration={properties?.duration || 15000}
      onClose={handleAlertClose}
      anchorOrigin={properties?.location || DEFAULT_LOCATION}
      sx={props.sx}
    >
      <MuiAlert
        onClose={handleAlertClose}
        severity={properties?.severity || "error"}
        sx={{ width: "100%" }}
      >
        {content?.body}
        {content?.details?.map((detail) => {
          return (
            <div key={detail}>
              <br /> {detail}
            </div>
          );
        })}
      </MuiAlert>
    </Snackbar>
  );
};
